import * as React from 'react'
import debounce from 'lodash.debounce'

export function useDebouncedState(timeout, initialState) {
  const [state, setStateImmediate] = React.useState(initialState)
  const setState = React.useCallback(debounce(setStateImmediate, timeout), [
    setStateImmediate,
  ])
  return [state, setState, setStateImmediate]
}
