import * as React from 'react'
import * as PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

export function CustomFieldError(props) {
  const translate = Herz.I18n.useTranslate(() => [props.translationKey], [
    props.translationKey,
  ])

  return (
    <Common.Box display="grid" gridGap="4px" wordBreak="break-word" pt="4px">
      <Common.Text fontSize={1} color="danger">
        {translate(props.translationKey)}
      </Common.Text>
    </Common.Box>
  )
}

CustomFieldError.propTypes = {
  translationKey: PropTypes.string,
}
