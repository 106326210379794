import * as React from 'react'

// Hookbased 'componentDidUpdate'
export function usePrev(value) {
  const ref = React.useRef(value)
  React.useEffect(() => {
    if (value !== ref.current) {
      ref.current = value
    }
  }, [value])
  return ref.current
}
